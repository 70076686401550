.table-container {
  margin: 20px auto; /* Center the container and add margin */
  overflow-x: auto; /* Allow horizontal scrolling if necessary */
  max-width: 100vw; /* Ensure the container width fits within the viewport */
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 15px; /* Added padding for better spacing */
}

.excel-table {
  width: 100%; /* Full width of the container */
  border-collapse: collapse;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.excel-table th,
.excel-table td {
  padding: 10px; /* Reduced padding for a smaller table */
  text-align: left;
  border: 1px solid #d0d0d0;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Added transition for smooth effects */
}

.excel-table th {
  color: white;
  font-weight: 100;
  text-transform: uppercase;
  background-color: #4a4a4a; /* Header background color */
}

.excel-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.excel-table tr:hover {
  background-color: #d1ecf1; /* Change background color on hover */
  /* Slightly enlarge the row */
}

.excel-table tr:last-child td {
  border-bottom: none;
}

.excel-table th,
.excel-table td {
  transition: background-color 0.3s ease;
}

.excel-table th {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #083885; /* Ensure header background color stays consistent */
}

@media (max-width: 768px) {
  .excel-table th,
  .excel-table td {
    border: 1px solid #d0d0d0;
    padding: 8px; /* Reduced padding for smaller screens */
    font-size: 12px; /* Adjusted font size for smaller screens */
  }
}
.see-more {
  display: inline-flex; /* Use flexbox for better alignment */
  align-items: center; /* Center the icon vertically */
  padding: 10px 15px; /* Add padding for better spacing */
 /* Button background color */
  color: #4a4a4a; /* Text color */
  border-radius: 5px; /* Rounded corners */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Make text bold */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transition */
}

.see-more:hover {
  background-color: #a2a2a2; /* Darken background on hover */
  transform: translateY(-2px); /* Slightly lift the button on hover */
}

.see-more i {
  margin-left: 5px; /* Add space between text and icon */
  transition: transform 0.2s ease; /* Smooth transition for icon */
}

.see-more:hover i {
  transform: translateX(2px); /* Move icon slightly on hover */
}
